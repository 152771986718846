 
<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
        <b-card title="Settings">
          <b-row>

                <b-col md="6">
                  Default:<br /><strong v-html="condition.naam"></strong>
                  <b-form-group
                    label="Custom condition name"
                    label-for="input_condition_name"
                    label-cols-md="12"
                  >
                    <b-form-input
                      v-model="custom.name"
                      id="input_triage_rule_name"
                      placeholder="Set custom condition name"
                    />
                  </b-form-group>    
                </b-col>  
                <b-col md="6">   
                  Default:<br /><strong v-html="condition.naam_officieel"></strong>         
                  <b-form-group
                    label="Custom sub title"
                    label-for="input_condition_alternative_name"
                    label-cols-md="12"
                  >
                    <b-form-input
                      v-model="custom.name_official"
                      id="input_triage_rule_name"
                      placeholder="Set custom sub title"
                    />
                  </b-form-group>
                </b-col>

          </b-row>
        </b-card>

        <b-row>
          <b-col md="6">
            <b-card>
              <h1>Original article:</h1>
              <br>
              <div v-html="condition.text"></div>
            </b-card>
          </b-col>
          
          
          <b-col md="6">

            <b-card>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="copyDefault"
                    style="margin-top:1em;"
                  >
                    <span>Copy default text</span>
                </b-button>   
                <br />
                <br />
                <quill-editor :id="'quill-editor'" v-model="custom.text" :options="getEditorOptions()">
                  <!-- Hier staan wat voorbeelden: https://github.com/quilljs/quill/issues/1335#issuecomment-340237112 -->
                  <div :id="'toolbar'" :identifier="'quill-editor-toolbar'" slot="toolbar">
                    <!-- Add a bold button -->
                    <button class="ql-bold">Bold</button>
                    <button class="ql-italic">Italic</button>
                    <button class="ql-underline">Underline</button>

                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>

                    <button class="ql-header" value="2"></button>
                    <button class="ql-link"></button>
                    <button class="ql-clean"></button>
                  </div>
                </quill-editor>

                <br>

   
                    <b-form-checkbox
                      v-model="custom.active"
                      id="custom_active"
                      value="1"
                    >Override original condition</b-form-checkbox>

                <!-- <input type="checkbox"> Override original condition -->
                <br>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="saveCondition"
                  style="margin-top:1em;"
                >
                  <feather-icon icon="SaveIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>     

              
            </b-card>  
          </b-col>  
                
        </b-row>
        



        <!-- SIDEBAR -->
        <b-sidebar
          width="40%"
          right
          shadow
          parent="body"
          default-index="1"
          spacer
          v-model="showSidebar"
          title="Media settings"
          class="sidebar"
        >
          <div class="px-3 py-2">
            <b-form @submit.prevent>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Video URL"
                    label-for="inputName"
                    label-cols-md="12"
                  >
                    <b-form-input v-model="activePart.media" id="inputName" placeholder="For example: https://vimeo.com/82740686" />
                  </b-form-group>
                </b-col>

                <!-- save -->
                <b-col md="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="showSidebar=false"
                  >
                    Update
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-sidebar>
        <!-- /SIDEBAR -->
    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BCard,
  BEmbed,
  BSidebar,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BOverlay,
    quillEditor,
    BCard,
    BEmbed,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading:true,
      showSidebar: false,
      activePart:false,
      condition:{},
      customtext:'',
      items: [
        {
          id: 1,
          selectedStatement: "All of these answers",
          prevHeight: 0,
        },
      ],
      nextTodoId: 2,
      editorOption: {
        width:500,
        modules: {
          toolbar: "#toolbar",
        },
      },

      //content: `<h2><span class="ql-font-serif">Quill Rich Text Editor</span></h2><br /><p>Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built for the modern web. With its <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.</p><br />`,

    };
  },
  computed: {
    custom() {
      if (this.condition.custom) return this.condition.custom;
      return {};
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    getEditorOptions() {
      return {
        width:500,
        modules: {
          toolbar: "#toolbar",
        },
      }
    },
    async loadCondition(id) {
      this.showSidebar=false;
      var url = '/conditions/'+id;
      const res = await this.callApi('get', url);
      this.condition = res.data.result;
      
      this.updateLoading();
    },
    async saveCondition() {
      this.showSidebar=false;
      var url = '/conditions';
      var method = 'post'
      this.isLoading = true;
      if (this.condition.id) {
          url = '/conditions/'+this.condition.id
          method = 'put'
      }
        

      const res = await this.callApi(method, url, this.condition);

      //console.log(res.data.result.id)
      if (res.data.result.id && method=="post") {
        this.$router.push({name:'condition-id', params:{id:res.data.result.id}})
        this.loadCondition(res.data.result.id);
      }
      else {
        this.loadCondition(this.$route.params.id);
      }

    },
    copyDefault() {
      if (confirm("This overwrites the (custom) text below. Are you sure you want to replace the text below with the original article?")){
        this.custom.text=this.condition.text;
      }
    },    
    updateLoading() {
      
      if (this.$route.params.id &&
        this.condition.naam
      ) {
        this.isLoading = false;
      }
      else if (!this.$route.params.id) {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    
    if (this.$route.params.id) {
      this.loadCondition(this.$route.params.id);
    }
    else {
      this.isLoading=false;
    }
  }
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.quill-editor {
  width:100%;
}
</style>

<style>
.ql-editor p{
  margin-bottom:1em !important;
}
</style>